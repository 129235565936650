<template>
  <el-container class="home">
    <el-aside
      style="transition: width 0.3s"
      :class="flag ? 'LeftBig' : 'leftSmall'"
    >
      <div class="touxiang">
        <span style="color: #ffffff" v-show="flag">后台管理</span>
        <i v-show="!flag" class="el-icon-s-shop titIcon"></i>
      </div>

      <!-- <el-menu
        default-active="2"
        class="el-menu-vertical-demo"
        background-color="#20222a"
        text-color="#fff"
        active-text-color="#fff"
      >
        <el-menu-item index="3" @click="$router.push('/')">
          <i class="el-icon-menu"></i>
          <span slot="title">概览</span>
        </el-menu-item>
        <el-submenu index="1">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span>门店管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="1-1" @click="$router.push('/shop')">
              门店列表
            </el-menu-item>
            <el-menu-item index="1-2" @click="$router.push('/shop/role')">
              门店角色
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="2">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span>商品管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="2-1" @click="$router.push('/goods')">
              商品列表
            </el-menu-item>
            <el-menu-item index="2-2" @click="$router.push('/goods/classify')">
              商品分类
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="5">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span>订单管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="3-1" @click="$router.push('/order/list')">
              订单列表
            </el-menu-item>
            <el-menu-item index="3-2" @click="$router.push('/order/after')">
              售后订单
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="6">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span>财务管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item
              index="4-1"
              @click="$router.push('/finance/shop/list')"
            >
              门店审核
            </el-menu-item>
            <el-menu-item
              index="4-2"
              @click="$router.push('/finance/user/list')"
            >
              用户审核
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="7">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span>权限管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item
              index="423-1"
              @click="$router.push('/jurisdiction/admin')"
            >
              管理员
            </el-menu-item>
            <el-menu-item
              index="43-2"
              @click="$router.push('/jurisdiction/role')"
            >
              角色
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-menu-item index="8">
          <i class="el-icon-menu"></i>
          <span slot="title" @click="$router.push('/menu/list')">菜单管理</span>
        </el-menu-item>
      </el-menu> -->

      <router-link
        v-for="(item, index) in leftTabs"
        :key="index"
        class="leftTab"
        @click.native="Clicktabs(index)"
        :id="index == thisClick ? clickColor : ''"
        :to="item.index_path"
      >
        <i v-show="!flag" :class="item.icon"></i>
        <span v-show="flag">{{ item.name }}</span>
      </router-link>
    </el-aside>
    <el-container>
      <el-header>
        <div class="headerLeft">
          <i v-if="flag" class="el-icon-s-fold shou" @click="zhedie"></i>
          <i v-else class="el-icon-s-unfold shou" @click="zhedie"></i>
        </div>

        <div class="hearderRight shou">
          <!-- <el-avatar size="30" :src="circleUrl"></el-avatar> -->
          <img
            style="width: 30px; margin-right: 4px"
            src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
            alt=""
          />
          <el-dropdown @click="handleClick">
            <div style="line-height: 30px" class="admin">
              当前账户: <span>{{ adminName }}</span>
            </div>
            <el-dropdown-menu slot="dropdown">
              <div @click="xiugai">
                <el-dropdown-item>修改密码</el-dropdown-item>
              </div>
              <div @click="goback">
                <el-dropdown-item>退出登录</el-dropdown-item>
              </div>
            </el-dropdown-menu>
          </el-dropdown>

          <!-- <i style="color: red; padding-right: 5px" class="el-icon-switch-button"></i> -->
          <!-- <span href="javasscrit:;" @click="goback">退出</span> -->
        </div>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
    <el-dialog title="修改密码" :visible.sync="dialogVisible" width="30%">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="原密码">
          <el-input
            placeholder="请输入原密码"
            show-password
            v-model="form.old_pwd"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input
            placeholder="请输入新密码"
            show-password
            v-model="form.user_pwd"
          ></el-input>
        </el-form-item>
        <el-form-item label="重复密码">
          <el-input
            placeholder="请再次输入新密码"
            show-password
            v-model="form.confirm_pwd"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit">确定</el-button>
          <el-button @click="quxiao">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-container>
</template>
<script>
import { getrolepower } from "@/api/irm.js";
import { loginout, user_sub_pwd } from "@/api/api.js";
export default {
  name: "index",
  data() {
    return {
      dialogVisible: false,
      form: {
        user_pwd: "",
        old_pwd: "",
        confirm_pwd: "",
      },
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      leftTabs: [
        // { name: "概览", icon: "el-icon-star-on", index_path: "/" },
        // { name: "门店管理", icon: "el-icon-s-home", index_path: "/shop" },
        // { name: "商品管理", icon: "el-icon-s-goods", index_path: "/goods" },
        // { name: "订单管理", icon: "el-icon-s-order", index_path: "/order" },
        // { name: "财务管理", icon: "el-icon-s-check", index_path: "/finance" },
        // // { name: "用户管理", icon: "el-icon-user-solid", index_path: "/user" },
        // { name: "权限管理", icon: "el-icon-s-operation", index_path: "/jurisdiction" },
        // { name: "活动管理", icon: "el-icon-s-flag", index_path: "/activity" },
        // { name: "菜单", icon: "el-icon-s-tools", index_path: "/menu" },
      ],
      clickColor: "clickColor",
      thisClick: 0,
      flag: true,
      adminName: "",
      promise_list: [],
    };
  },
  created() {
    this.setTabs();
    this.getrole();
    this.adminName = window.localStorage.getItem("admin_name");
  },

  methods: {
    handleClick(e) {
      console.log(e);
      alert("3");
    },
    xiugai() {
      // this.$router.push("/login");
      this.dialogVisible = true;
    },
    quxiao() {
      this.dialogVisible = false;
      this.form = {
        user_pwd: "",
        old_pwd: "",
        confirm_pwd: "",
      };
    },
    async user_sub_pwd() {
      const { data } = await user_sub_pwd(this.form);
      if (data.code != 200) {
        return this.$message.error(data.data);
      }
      this.$message({
        message: data.data,
        type: "success",
      });
      this.dialogVisible = false;
      this.form = {
        user_pwd: "",
        old_pwd: "",
        confirm_pwd: "",
      };
    },
    onSubmit() {
      this.user_sub_pwd();
    },
    async getrole() {
      let role_id = window.localStorage.getItem("admin_roleID");
      const { data } = await getrolepower({ role_id: role_id });
      this.leftTabs = data.data.menu_list;
      // this.digui(data.data.menu_list);
      // let ary = new Set(this.promise_list);
    },
    digui(ary) {
      ary.forEach((item) => {
        if (item.children) {
          this.digui(item.children);
        } else {
          this.promise_list.push(item.app + item.controller + item.action);
        }
      });
    },
    zhedie() {
      this.flag = !this.flag;
    },
    Clicktabs(index) {
      this.thisClick = index;
      window.sessionStorage.setItem("index", this.thisClick);
      window.localStorage.setItem("leftindex", 0);
    },
    async goback() {
      const { data } = await loginout();
      if (data.code != 200) return this.$message.error(data.data);
      this.$message.success(data.data);
      window.localStorage.removeItem("admin_token");
      window.localStorage.removeItem("admin_name");
      window.localStorage.removeItem("admin_roleID");
      this.$router.push("/login");
      window.sessionStorage.removeItem("index");
    },
    setTabs() {
      if (window.sessionStorage.getItem("index")) {
        let index = window.sessionStorage.getItem("index");
        this.thisClick = index;
      } else {
        this.thisClick = 0;
      }
    },
  },
};
</script>
<style scoped lang="less">
.home {
  .LeftBig {
    width: 120px !important;
    height: 100vh;
    background-color: #20222a;
    .touxiang {
      width: 120px;
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 46px;
        height: 46px;
        border-radius: 50%;
      }
    }
    .leftTab {
      display: block;
      width: 100%;
      height: 48px;
      text-align: left;
      padding-left: 30px;
      line-height: 48px;
      color: #bdbdc0;
      font-size: 16px;
      i {
        font-size: 18px;
        padding-right: 3px;
      }
      &:hover {
        color: #fff;
      }
    }
  }
  .leftSmall {
    width: 60px !important;
    height: 100vh;
    background-color: #20222a;
    .titIcon {
      display: block;
      height: 65px;
      color: #bdbdc0;
      text-align: center;
      line-height: 65px;
      font-size: 28px;
    }
    .leftTab {
      display: block;
      width: 100%;
      height: 48px;
      text-align: left;
      padding-left: 20px;
      line-height: 48px;
      color: #bdbdc0;
      font-size: 16px;
      i {
        font-size: 18px;
        padding-right: 3px;
      }
      &:hover {
        color: #fff;
      }
    }
  }
  /deep/.el-header {
    background-color: #fff;
    height: 50px !important;
    padding-left: 30px;
    padding-right: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .headerLeft {
      width: 80px;
      height: 50px;
      font-size: 26px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .hearderRight {
      display: flex;
      span:hover {
        color: red;
      }
      .admin {
        margin-right: 10px;
        font-size: 12px;
        color: #999;
        span {
          font-size: 16px;
          color: #111;
        }
      }
    }
  }
  /deep/.el-main {
    padding: 0 !important;
    overflow: auto;
  }
}

#clickColor {
  background-color: #009688;
  color: #fff;
}

.el-menu {
  border: none;
  width: 120px;
  /deep/.el-submenu__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    color: rgb(255, 255, 255);
    background-color: rgb(84, 92, 100);
    /deep/.el-submenu__icon-arrow {
      right: 5px;
    }
  }
  /deep/.el-menu-item {
    width: 120px;
    background-color: pink;
    min-width: 0 !important;
    text-align: center;
    padding: 0 !important;
  }
  .el-icon-location {
    margin-right: 0;
  }
  /deep/.el-menu-item-group__title {
    display: none;
  }
}
</style>